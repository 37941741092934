import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux"
import { ReactReader } from "../modules";
import 'antd/dist/antd.css';
import Epub from "epubjs/lib/index";
import { SettingOutlined,CloseOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import DataStore from '../datastore/Utility';
import Header from '../component/pageheader/pageHeader';
import { Spin, Col, Row, Drawer, Switch, Select, Slider ,Space,Breadcrumb,message,Progress} from 'antd';
import { fontaction,lineaction,nightmodeaction ,fontfamilyaction} from '../redux/actions/dashboardActions';
import { Container, ReaderContainer, FontSizeButton, } from "./Components";
import { useIndexedDB } from 'react-indexed-db';
import {useLocation,useHistory } from 'react-router-dom'
import Axios from "axios";
import { ASSET_URL, SERVER_URL } from "../api/content-novareader";
import CryptoJS from 'crypto-js';
import { bookcover } from "../api/api";
import PageHader from "../component/pageheader/pageHeader";

const { Option } = Select;
// global.ePub = Epub;
const Reader = ({myfontactin,mylineaction,mynightmodeaction,myfontfamilyaction,epubstyle,selecteditem,istocopen,...otherProps}) => {
  const nlocation = useLocation();
  const contentid= nlocation.state.contentid
  const fuzzyid= nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const bookinfo=nlocation.state.info
  const author=nlocation.state.auth
  const elasticid=otherProps.match.params.elasticid
  const type=nlocation.state.type
  const cover=nlocation.state.cover
  const [page, setPage] = useState('')
  const renditionRef = useRef(null)
  const tocRef = useRef(null)
  const { add, getAll } = useIndexedDB('book');
  const [loading, setloading] = useState(true)
  const [location, setlocation] = useState(0)
  const [localFile, setlocalFile] = useState("")
  const [localName, setlocalName] = useState("")
  const [rendition, setrendition] = useState(null)
  const [visible, setVisible] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [fontProps,setfontProps]=useState([])
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
 const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
 const history = useHistory();
 const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));

  useEffect(() => {
    findByNameAndType(titel, 'epub', lpp_userCredentials).then(results => {
      if (results.length>0) {
        var file = results[0];
          message.success('Loading ....');
          setCoverImage(URL.createObjectURL(file.img));
          
          blobToString(file.file)
            .then(async (encryptedData) => {
              // Step 3: Decrypt the encrypted data
              const keyBytes = new TextEncoder().encode("1234567890123456");
          
              // Import AES key for decryption
              const cryptoKey = await window.crypto.subtle.importKey(
                "raw",
                keyBytes,
                { name: "AES-CTR" },
                false,
                ["decrypt"]
              );
          
              // Extract IV (first 16 bytes) and ciphertext
              const iv = encryptedData.slice(0, 16);
              const ciphertext = encryptedData.slice(16);
          
              // Perform AES-CTR decryption
              const decryptedBuffer = await window.crypto.subtle.decrypt(
                { name: "AES-CTR", counter: iv, length: 64 },
                cryptoKey,
                ciphertext
              );
    
              const epubBlob = new Blob([decryptedBuffer]);
              const url = URL.createObjectURL(epubBlob);
              localStorage.setItem('epuburl', url);
      
              // Restore last reading location if available
              const location = JSON.parse(localStorage.getItem(titel));
              if (location) {
                setlocation(location.start);
              }
      
              // Update state
              setlocalName(titel);
              setlocalFile(epubBlob);
              setloading(false);
            });
        } else {
          // Fetch data if file is not available locally
          if (lpp_userCredentials?.apiKey) {
            getdata(lpp_userCredentials.apiKey);
          } else {
            console.error('API Key not found in user credentials');
            message.error('You don\'t have access to read this book');
          }
        }
      })
      .catch(error => {
        console.error('Failed to retrieve file by index:', error);
        setloading(false);
      });
  }, [titel]);  

  async function findByNameAndType(name, type, lpp_userCredentials) {
    try {
      const allRecords = await getAll(); // Get all records from the 'book' store
      
      // Filter by name and type
      const results = allRecords.filter(
        (record) => record.name === name && record.type === type && record.userId === lpp_userCredentials.id
      );
      return results;
    } catch (error) {
      console.error('Error fetching records:', error);
    }
  }

const blobToString = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result); // Read as string
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob); // Read Blob as text
  });
};



  const savefile = ({name,file}) => {
    add({ name: name, file: file }).then(
      event => {
        console.log('ID Generated: ', event.target.result);
      },
      error => {
        console.log(error);
      }
    );
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      if (!blob) {
        resolve(null);
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject("Error converting blob to Base64: " + error);
      reader.readAsDataURL(blob);
    });
  };
  
  const getdata = async (apikey) => {
    message.success('Downloading ....');
    setlocalName(titel);

    const res = await fetch(`${ASSET_URL}${fuzzyid}/${cover}`); // Fetch the image
    const imgBlob = await res.blob(); // Convert response to Blob
    setCoverImage(URL.createObjectURL(imgBlob));
    
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
  
    Axios.get(`${SERVER_URL}content/${type}/epub/${fuzzyid}.epub`, {
      responseType: "arraybuffer",
      headers: { 'apikey': apikey },
      ...options,
    })
      .then((encryptedData) => {
        Axios.get(`${ASSET_URL}${fuzzyid}/${cover}`, { responseType: "blob" })
          .then(async (response) => {
            const encContent = encryptedData.data;
            const content = encryptedData.data;
            message.success('Loading ....');
            const keyBytes = new TextEncoder().encode("1234567890123456");
        
            // Import AES key for decryption
            const cryptoKey = await window.crypto.subtle.importKey(
              "raw",
              keyBytes,
              { name: "AES-CTR" },
              false,
              ["decrypt"]
            );
        
            // Extract IV (first 16 bytes) and ciphertext
            const iv = content.slice(0, 16);
            const ciphertext = content.slice(16);
        
            // Perform AES-CTR decryption
            const decryptedBuffer = await window.crypto.subtle.decrypt(
              { name: "AES-CTR", counter: iv, length: 64 },
              cryptoKey,
              ciphertext
            );
  
            const epubBlob = new Blob([decryptedBuffer]);
            const epubUrl = URL.createObjectURL(epubBlob);
            localStorage.setItem('epuburl', epubUrl);
  
            // IndexedDB Insert
            const encryptedBlob = new Blob([encContent], { type: 'application/octet-stream' });
            add({
              name: titel,
              file: encryptedBlob,
              img: imgBlob,
              type: "epub",
              info: bookinfo,
              auth: author,
              userId: lpp_userCredentials.id
            })
              .then(() => {
                localStorage.setItem('currentbook', titel);
                let location = JSON.parse(localStorage.getItem(titel));
                console.log("Last location:", location);
  
                if (location) {
                  setlocation(location.start);
                }
  
                setlocalFile(epubBlob);
                setloading(false);
              })
              .catch((error) => {
                console.error("IndexedDB Error:", error);
                setlocalFile(epubBlob);
                setloading(false);
              });
          })
          .catch((error) => {
            console.error("Cover Image Fetch Error:", error);
            message.error('Failed to fetch cover image.');
            setloading(false);
          });
      })
      .catch((error) => {
        message.destroy();
        if (error.response) {
          const erroJson = JSON.parse(new TextDecoder().decode(new Uint8Array(error.response.data)));
          message.error(erroJson.message);
        }
        setTimeout(() => { history.goBack(); }, 3000);
      });
  };  

  const onLocationChanged = location => {
    console.log(location)
    setlocation(location)
   
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

const fontSizeChange = (value) => {
     myfontactin(value)
     styleSetting()
}

  const lineHeightChange = (value) => {
    mylineaction(value)
    styleSetting()

  }
  const nightModeChange = (value) => {
    if(value){
      mynightmodeaction("#ffffff","#000000","100%")
      styleSetting()
    }else{
      mynightmodeaction("#000000","#ffffff","0%")
      styleSetting()
    }
    onClose();

  }
  function onFontChange(value) {
    myfontfamilyaction(value)
    console.log(value)
    styleSetting()
  }

  
  const Settingdrawer = () => {
    return (
      <div style={{ marginLeft: '5px', marginRight: '5px', padding: '20px'}}>
         
        <div style={{ width: '100%', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <p style={{ fontWeight: '500',marginTop:'14px' }}>Night Mode</p>
          <Switch checked={epubstyle.bgcolor=="#ffffff"?false:true} onChange={nightModeChange} />
        </div>
        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Font Size</p>
          <Slider
            defaultValue={epubstyle.fontsize}
            min={1}
            max={20}
            onChange={fontSizeChange}/>
        </div>
        <div style={{ width: '100%', height: '70px', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
          <p style={{ fontWeight: '500' }}>Line Height</p>
          <Slider
            defaultValue={epubstyle.lineheight}
            min={1}
            max={20}
            onChange={lineHeightChange}/>
        </div>
      </div>
    )
  }
  const styleSetting = () => {
    rendition.themes.register("defult", {
     "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
        "line-height": `${epubstyle.lineheight} !important`,
        "font-size": `${epubstyle.fontsize}px !important`,
      },
      "p": {
        "color": epubstyle.color,
        "line-height": `${epubstyle.lineheight} !important`,
        "font-size": `${epubstyle.fontsize}px !important`,
      },
      "table": {
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
      },
      "a": {
        "line-height": `${epubstyle.lineheight} !important`,
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important"
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
       },
     
    });
    rendition.themes.select("defult");
  }


  const getRendition = rendition => {
    setrendition(rendition);
    rendition.themes.register("defult", {
      "body": {
        "padding-top": "0px !important",
        "padding-bottom": "0px !important",
        "margin": "0 !important",
        "background":epubstyle.bgcolor,
        "color": epubstyle.color,
      },
      "table": {
        "background":epubstyle.bgcolor,
        "margin-left": "auto",
        "margin-right": "auto",
        "display": "block",
        "overflow-x": "auto",
      },
      "a": {
        "color": "#1C92E8",
        "text-decoration": "none !important",
        "-webkit-text-fill-color": "inherit !important",
      },
      "img": {
        "filter": `grayscale(${epubstyle.filter})`,
        "background":epubstyle.bgcolor,
       
      },
     
    });
    rendition.themes.select("defult");
  };

  const dataStore = new DataStore();
  if (loading) {
    return (
         <div>
              <PageHader titel={titel}/>                
             <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }}></p>
                  <div style={{ height: '80%', width: '100%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                     <img src={coverImage} style={{ height: '100%', width: '94%', backgroundColor: '#cccccc', margin:'5px'}} alt="" loading="lazy" />
                  </div>
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                    <Progress percent={downloadInfo.progress} size="default" status="active"  />
                  </div>
                </Col>
              </Row>
            </div>
         
    )
  }
  return (
   
    <Container>
      <div >
        <ReaderContainer fullscreen={true}>
          <ReactReader dataStore={dataStore}
            url={localFile}
            title={localName}
            location={location}
            locationChanged={onLocationChanged}
            history={history}
            getRendition={getRendition}/>
          <FontSizeButton onClick={showDrawer}>
           {istocopen? null:<SettingOutlined style={{color:epubstyle.color,fontSize:"17px"}} />}
          </FontSizeButton>
        </ReaderContainer>
        <Drawer
          title="Settings"
          placement="right"
          mask={false}
          closable={true}
          closeIcon={<CloseOutlined style={{color:epubstyle.color}} />}
          onClose={onClose}
          drawerStyle={{color:epubstyle.color,backgroundColor:epubstyle.bgcolor}}
          open={visible}>
          {Settingdrawer()}
        </Drawer>
      </div>
    </Container>
  );

}
const mapStateToProps = state => ({
  epubstyle: state.App.epubstyle,
  selecteditem: state.App.selecteditem,
  istocopen:state.App.istocopen
})

const mapDispatchToProps = {
  myfontactin:fontaction,
  mylineaction:lineaction,
  mynightmodeaction:nightmodeaction,
  myfontfamilyaction:fontfamilyaction,
}



export default connect(mapStateToProps,mapDispatchToProps)(Reader);
