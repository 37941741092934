import React, { Component} from "react";
import { connect } from "react-redux"
import PropTypes from "prop-types";
import Epub from "epubjs";
import defaultStyles from "./style";
import 'antd/dist/antd.css';
import '../../styles/Mystyle.css';
import './epub.css';
import { updateepublocation} from '../../redux/actions/dashboardActions';
import Hammer from 'hammerjs';

import {Drawer, Modal, List, Form, Input, Button} from 'antd';
import { FileTextOutlined, GoogleOutlined,InfoCircleOutlined ,LoadingOutlined} from '@ant-design/icons';
const { TextArea } = Input;
const { Search } = Input;
 
// global.ePub = Epub;  Fix for v3 branch of epub.js -> needs ePub to by a global var
let varl = 0;
class EpubView extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isloading:true,
      toc: [],
      highlightToolBox: true,
      highlightToolBoxPlacement: 'bottom',
      isNotesVisible:false,
      noteText : '',
      searchDrowBox: false,
      searchValue:'',
      toolbox:false,
      isModalVisible:false,
      chaptere:'cover',
      bookname:localStorage.getItem('currentbook'),
    };
    this.drawerbox = false;
    this.viewerRef = React.createRef();
    this.location = props.location;
   
    this.book = this.rendition = this.prevPage = this.nextPage = null;
   
  }

  componentDidMount() {
    Modal.success({
      centered:true,
      icon:<LoadingOutlined/>,
      content:(
        <div className="loading">
          Loading...
        </div>
      ),
      okButtonProps:{ style: { display: 'none' } },
    });
    this.initBook(true);
    document.addEventListener("keyup", this.handleKeyPress, false);
  }

  initBook() {
    
    const { url, tocChanged, epubInitOptions } = this.props;
    if (this.book) {
      this.book.destroy();
    }
    this.book = new Epub(url, epubInitOptions);

    this.book.loaded.navigation.then(({ toc }) => {
      let finaltoc=toc;
    for(var i=0;i<finaltoc.length;i++){
       var href=finaltoc[i].href
      if (href.indexOf("../") != -1) {
        finaltoc[i].href = href.replace(/\..\//g, '');
      } else if (href.indexOf("/") == -1) {
          var tmphref = this.book.spine.items[0].href.substring(0,this.book.spine.items[0].href.lastIndexOf("/"));
          if (tmphref!="" && tmphref != undefined) {
            finaltoc[i].href = tmphref + "/" + href;
          }
      }
     for(var j=0;j<finaltoc[i].subitems.length;j++){
       var subhref=finaltoc[i].subitems[j].href
       if (subhref.indexOf("../") != -1) {
        finaltoc[i].subitems[j].href = subhref.replace(/\..\//g, '');
      }else if (subhref.indexOf("/") == -1) {
        var tmphref = this.book.spine.items[0].href.substring(0,this.book.spine.items[0].href.lastIndexOf("/"));
        if (tmphref!="" && tmphref != undefined) {
          finaltoc[i].subitems[j].href = tmphref + "/" + subhref;
        }
    }
     }
    }
      this.setState(
        {
          isLoaded:true,
          toc: finaltoc
        },
        () => {
          tocChanged && tocChanged(finaltoc);
         
          this.initReader();
        }
      );
    });
  }

  componentWillUnmount() {
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    document.removeEventListener("keyup", this.handleKeyPress, false);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !this.state.isLoaded ||
      nextProps.location !== this.props.location ||
      nextProps.location !== this.props.location
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location !== this.props.location &&
      this.location !== this.props.location
    ) {
      this.rendition.display(this.props.location);
    }
    if (prevProps.url !== this.props.url) {
      this.initBook();
    }
  }

  initReader() {
    console.log("epub loader");
    const { toc } = this.state;
    const { location, epubOptions, getRendition } = this.props;
    const node = this.viewerRef.current;
    this.rendition = this.book.renderTo(node, {
      //  manager: "continuous",
      flow: "scrolled-doc",
      width: "100%",
      height: "100%",
      spread: "none",
      snap: true,
      ...epubOptions
    });

    document.querySelectorAll("iframe").forEach(iframe => {
      iframe.setAttribute("sandbox", "allow-scripts allow-popups");
    });    

    this.prevPage = () => {
      this.rendition.prev();
    };
    this.nextPage = () => {
      this.rendition.next();    
    };
    this.registerEvents();

    getRendition && getRendition(this.rendition);
    if(typeof location === "string" || typeof location === "number") {
      console.log(1);
      Modal.destroyAll();
      this.rendition.display(location);           
    } else if(toc.length > 0 && toc[0].href) {
      console.log(2);
      Modal.destroyAll();
      this.rendition.display(toc[0].href);
    } else {
      console.log(3);
      Modal.destroyAll();
      this.rendition.display();
    }

    this.rendition.hooks.content.register((contents) => {

        let doc = contents.document;
        let coverImage = doc.querySelector('img[alt="cover"]');
    
        if (coverImage) {
          coverImage.style.height = "500px";
        }
        setTimeout(() => {
          const doc = contents.document;
          const isIOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
  
          if (doc) {
            if (isIOS) {
              document.querySelectorAll("iframe").forEach(iframe => {
                iframe.setAttribute("sandbox", "allow-scripts allow-popups allow-top-navigation allow-top-navigation-by-user-activation");                            
              });
              doc.querySelectorAll("a").forEach((link) => {
                const url = link.getAttribute("href");
                if (url && !(url.startsWith("https://") || url.startsWith("http://"))) {
                  link.removeAttribute("href");
                }
              });    
            
              
            } else {
              doc.querySelectorAll("a").forEach((link) => {
                const url = link.getAttribute("href");
                if (url && (url.startsWith("https://") || url.startsWith("http://"))) {
                  link.setAttribute("target", "_blank");
                  link.setAttribute("rel", "noopener noreferrer");
    
                  link.addEventListener("click", (event) => {
                    event.preventDefault();
                    window.open(url, "_blank");
                  });
                }
              });                
            }
          }
        }, 500); // Ensure content is fully loaded
        
    }); 
    
  }

  
   getChapterbyid= async(locations)=>{
    this.props.getchapter(locations,this.book.navigation.toc)

   }
   mouseListener(document) {
  }
  
  
  registerEvents() {
    // const { fooder } = this.state;

    const { handleKeyPress, handleTextSelected } = this.props;
    this.rendition.on("locationChanged", this.onLocationChange);
    this.rendition.on("keyup", handleKeyPress || this.handleKeyPress);
     this.rendition.on('relocated', this.getChapterbyid);
     this.rendition.on('rendered', (e, iframe) => {
      this.mouseListener(iframe.document, this.rendition, );
     
    });
  }
  highlightColor = (handleTextSelected, color) => {
    const dataStore =  this.props.dataStore;
    this.createHighlight(dataStore.handleTextSelected, color, 'highlight', '');
    
  }
  onFinish = (values) => {
    const {noteText} = this.state;
    this.createHighlight(noteText, "#fdfd81", 'highlight', values.note);
    Modal.destroyAll();
    this.setState({toolbox:false});
    this.setState({toolbox:false});
    
   
  }
  modelcancelBox = () =>
  {
    Modal.destroyAll();
    this.setState({toolbox:false});
  }
  cancelBox = () =>
  {
    Modal.destroyAll();
    this.setState({toolbox:false});
  }
  cancelToolbox = () =>{
    
  //  Modal.destroyAll();
  }
  onFinishFailed =() =>{

  }
  addNotes =(text, clr)=>{
    const dataStore =  this.props.dataStore;
    this.setState({noteText:dataStore.handleTextSelected})
    Modal.destroyAll();
    this.setState({toolbox:false});
    Modal.info({
      content: (
        <div className="notbox">
          <Form layout="vertical" name="basic" initialValues={{ remember: true }} onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
            <Form.Item label="Note" name="note" rules={[{required: true, message: 'Please input your note!'}]} >
              <TextArea rows={4} />
            </Form.Item>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:"space-evenly"}}>
            <Button type="primary" onClick={this.cancelBox} style={{backgroundColor:'#FF0000',border:'none'}}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" >
                Submit
              </Button>
              
              </div>
          </Form>
        </div>
      ),
      okButtonProps:{ style: { display: 'none' } },
    });

    //this.setState({isNotesVisible:true});
  }

  changeState = () => {
    this.setState({toolbox:false});

  }
  searchSelectedWord = async(handleTextSelected) => {
    let dataStore = this.props.dataStore;
    let text = await this.getText(handleTextSelected);
    this.props.dataStore.searchText = text;
}
searchGoogleWord = async(handleTextSelected) => {
  const dataStore = this.props.dataStore;
  let text = await this.getText(dataStore.handleTextSelected);
  window.open('http://google.com/search?q='+text);
}
searchWikiWord = async(handleTextSelected) => {
  const dataStore = this.props.dataStore;
  let text = await this.getText(dataStore.handleTextSelected);
  window.open('https://en.wikipedia.org/wiki/'+text);
}
getText = async(handleTextSelected)=>{
  let text = '';
  if (handleTextSelected) {
    text = await this.book.getRange(handleTextSelected).then(async(range)=> {
     let stext = range ? (range.toString() || range.startContainer.data.substring(0, 200)) : '<no text>';
      return stext;
    });
  }  
  return text;
}
  createBookmark = async(location, type, color) => {
    this.book.getRange(location).then(async(range)=> {
    let result = await this.markFromCfi(location, range, type, false, color);
    this.rendition.annotations.highlights.push(result);
    return true;
    });
  }
  deleteBookmark = async(location, type, color) => {
    let bIndex = this.rendition.annotations.highlights.findIndex(function (element) { return (element.cfi == location); });
    if(bIndex > -1) {
      let deletedB = this.rendition.annotations.highlights.splice(bIndex, 1);
    }
  }
  createHighlight = async(handleTextSelected, color, type, note) => {
    let rendition = this.rendition;
    if (handleTextSelected) {
        this.book.getRange(handleTextSelected).then(async(range)=> {
            let result = await this.markFromCfi(handleTextSelected, range, type, false, color, note);
            this.rendition.annotations.highlights.push(result);
            this.rendition.annotations.highlight(handleTextSelected, {}, (e) => {
          });
          this.applyCustomColor(handleTextSelected, color);
          let contents = this.rendition.manager.getContents()[0];
          if (contents) {
            contents.window.getSelection().removeAllRanges();
            Modal.destroyAll();
            this.setState({toolbox:false});
          }
        });
    }
  }
  markFromCfi=(cfi, range, type, annotationText, color, note)=> {
    return new Promise((resolve, reject) => {
      let text = range ? (range.toString() || range.startContainer.data.substring(0, 200)) : '<no text>';
      let getBook =  this.book;
      let spineItem = this.book.spine.get(cfi);
      let navItem = this.book.navigation.toc.find(function(element){return (element.herf == spineItem.href)});
      if (!navItem) {navItem = {label: ''}; }
      if ((type == 'bookmark') && (navItem.label.length > text.length)) {text = navItem.label;}
      let perc = this.book.locations.percentageFromCfi(cfi);
      perc = perc ? 100 * parseFloat(perc.toFixed(2)) : 0;
      let dogear = `Chapter ${navItem.label} (${perc}%)`;
      let hl;
      let savedOn = new Date(new Date().getTime() + (60000 * 10));
      this.copyText = text;
      let labels = ''
      if( navItem.label == '')
      {
        labels = spineItem.idref;
      }
      else
      {
        labels = navItem.label;
      }

      if(note == '')
      {
        hl = {type: type, cfi: cfi, text: text, annotationText: annotationText, dogeartext: dogear, range: range,
            chapterLabel: labels, positionPercentage: perc, color: color, savedon: savedOn, underline: false,
             notesavail: false};
      }
      else
      {
        hl = {type: type, cfi: cfi, text: text, annotationText: annotationText, dogeartext: dogear, range: range,
          chapterLabel: labels, positionPercentage: perc, color: color, savedon: savedOn, underline: false,
          notesavail: true, note:note};
      }
        
      resolve(hl);
      console.log(hl)
      var currentbook=localStorage.getItem("currentbook")
      currentbook=currentbook + "hl";
      console.log(currentbook)
      var localhl = JSON.parse(localStorage.getItem(currentbook) || "[]");
      localhl.push(hl);
      localStorage.setItem(currentbook, JSON.stringify(localhl));
    });
    // return hl;
}

applyCustomColor=(handleTextSelected, Hcolors)=>{
  let iframe = this.getEpubIframe();
  if (iframe) {
      let svg = iframe.parentElement.getElementsByTagName('svg')[0];
      if (svg) {
          for (let i = 0; i < svg.children.length; i++ ) {
              let g = svg.children[i];
              if (g.dataset.epubcfi === handleTextSelected) {
                  if(g.getAttribute('fill') !== Hcolors){
                      if(g.getAttribute('fill') == "yellow"){
                          g.setAttribute('fill', '#fff');
                          g.setAttribute('fill', Hcolors); 
                      } else {
                          g.setAttribute('fill', Hcolors);
                      }
                  }
              }
          }
      }
  }
}
getEpubIframe=()=> {
    
  let iframes = document.getElementsByTagName('iframe');
  if (iframes) {
      for(let i = 0; i < iframes.length; i++ ) {
          if ( iframes[i].id.indexOf('epubjs') > -1) {
              return iframes[i];
          }
      }
  }
  return false;
}




  onLocationChange = loc => {

    let book =localStorage.getItem('currentbook')
    const { location, locationChanged, } = this.props;
    const newLocation = loc && loc.start;
    if (location !== newLocation) {
      this.location = newLocation;
      locationChanged && locationChanged(newLocation);
    }
     localStorage.setItem(book, JSON.stringify(loc));


    

  };

  renderBook() {
    const { styles } = this.props;
    return  <div id="viewer" ref={this.viewerRef} style={styles.view} />;
  }

  handleKeyPress = ({ key }) => {
    key && key === "ArrowRight" && this.nextPage();
    key && key === "ArrowLeft" && this.prevPage();
  };
  onClose = async() => {
     this.setState({highlightToolBox: false});
    
  }
  
  onChange = e => {
    this.setState({
      highlightToolBoxPlacement: e.target.value,
    });
  };
  closeIcon = () =>{
    this.setState({highlightToolBox: false});
  }
  handleOk = () => {
    this.setState({isNotesVisible: false});
  };

  handleCancel = () => {
    this.setState({isNotesVisible: false});
  };
  drawerOnClose = () => {
    this.setState({"searchDrowBox":false});
   };
 
  openSearch=()=>{
    
    this.setState({searchDrowBox:true});
  }
  onSearch = async(value) =>{
    debugger
    if(value!="")
    {
    let getSearchContent = await this.doSearch(value);
    this.setState({searchValue:getSearchContent})
    }
    debugger;
  }
  doSearch(q) {
    // let me = this.readerRef.current;
    // debugger;
    // tslint:disable-next-line: indent
    return Promise.all(this.book.spine.spineItems.map(item => {
      return item.load(this.book.load.bind(this.book)).then(doc => {
        void(doc);
        let results = item.find(q);
        item.unload();
        return Promise.resolve(results);
      });
    })).then(results => Promise.resolve([].concat.apply([], results)));
  }
  
  
  
  render() {

    const { isLoaded, highlightToolBox, highlightToolBoxPlacement, searchDrowBox,searchValue, isModalVisible,fooder } = this.state;
    const { loadingView, styles, drawerbox } = this.props;
    return (
      <div style={styles.viewHolder}  >
        

        { this.renderBook()}
    
      </div>
       
    );
  }
}

EpubView.defaultProps = {
  loadingView: null,
  locationChanged: null,
  tocChanged: null,
  styles: defaultStyles,
  epubOptions: {},
  epubInitOptions: {},
  drawerbox:false,
};

EpubView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  loadingView: PropTypes.element,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  styles: PropTypes.object,
  getchapter:PropTypes.func,
  epubInitOptions: PropTypes.object,
  epubOptions: PropTypes.object,
  getRendition: PropTypes.func,
  handleKeyPress: PropTypes.func,
  drawerbox:PropTypes.bool,
  handleTextSelected: PropTypes.func,
};

// const mapStateToProps = state => ({
//   epublocation: state.App.currentlocation,

// })
// const mapDispatchToProps = {
//   myepublocation:updateepublocation,
 
// }



export default EpubView;
